import React, { useRef, useEffect, useState } from "react";
import SearchBar from "./components/SearchBar";
import _ from "lodash";
import Color from "color";

export const App = () => {
  const domain = "colourcure.xyz";
  const [query, setQuery] = useState("");
  const divRef = useRef<any>(null);
  const formRef = useRef<any>(null);
  const [data, setData] = useState<string[] | undefined>();

  const getData = async () => {
    setData(undefined);
    await fetch(
      `https://${domain}/proxy/get?url=${encodeURIComponent(
        `https://palett.es/API/v1/palette?v=${Math.random()}`
      )}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result.response);
          if (result.response) {
            const json = result.response;
            setData(json);
          }
        },
        (error) => {}
      );
  };

  useEffect(() => {
    async function parse() {
      await getData();
    }
    if (_.isEmpty(data)) {
      parse();
    }
  }, [data]);
  console.log(data);
  return (
    <div className="w-screen h-screen overflow-none bg-gray-200">
      <div
        ref={divRef}
        className={`w-full h-full flex flex-col items-center justify-center z-50`}
      >
        <h1 className="-mt-64 text-lg font-extrabold mb-4 text-pink-400">
          {domain.replace(".xyz", "")}
        </h1>
        <div className="mb-24">
          <div className="bg-white rounded-md shadow-xl overflow-hidden flex flex-col h-64 w-96 max-w-full">
            {data && (
              <>
                {data.map((color) => {
                  const c = Color(`${color}`);
                  let n = Color(`${color}`);
                  let maxTries = 15;
                  do {
                    maxTries--;
                    n = c.isDark() ? n.lighten(0.3) : n.darken(0.3);
                    if (maxTries === 0 && c.contrast(n) < 5) {
                      n = c.isDark() ? Color("white") : Color("black");
                    }
                  } while (c.contrast(n) < 5 && maxTries > 0);
                  return (
                    <div
                      key={color}
                      style={{
                        backgroundColor: `${color}`,
                        height: `${0.2 * 100}vw`,
                        minHeight: "50px",
                        color: n.hex(),
                      }}
                      className="flex flex-col items-start justify-end py-3 pl-2 pr-3 text-xl font-bold"
                    >
                      {color}
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {data && data?.length > 0 && (
            <div className="w-full">
              <button
                onClick={() => getData()}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center text-xs mx-auto mt-2"
              >
                Reload Palette
              </button>
            </div>
          )}
        </div>
        <div className="absolute bottom-0 right-auto left-auto flex items-center justify-center">
          <a
            href={`https://${domain}/privacy`}
            className="text-xs mx-2 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          &bull;
          <a
            href={`https://${domain}/tos`}
            className="text-xs mx-2 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            TOS
          </a>
        </div>
      </div>
    </div>
  );
};
